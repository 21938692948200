import React from 'react';

class QuizPrompt extends React.Component {
    render() {
     return(
        <div className="quiz-prompt">
           <h5>PROMPT</h5>
           <h6>Please read the following to get more context on the problem:</h6>
           <p>{this.props.promptText}</p>
           <p className="quiz-hint"><span>HINT:</span> {this.props.promptHint}</p>
        </div>
     );
    }
  }

  export default QuizPrompt;