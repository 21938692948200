import React from 'react';
import PropTypes from "prop-types";
import '../src/css/quiz-page.css';
import Footer from './components/common/footer';
import QuizHeader from './components/quiz-page/quiz-header';
import QuizMetaStrip from './components/quiz-page/quiz-meta-strip';
import QuizPrompt from './components/quiz-page/quiz-prompt';
import QuizMedia from './components/quiz-page/quiz-media';
import SolutionToggleBar from './components/quiz-page/solution-toggle';
import SolutionBox from './components/quiz-page/solution-box';
import DiscussQuiz from './components/quiz-page/discuss-quiz';
import Fade from 'react-reveal/Fade';
import quizzes from './database/index.json';

class QuizPage extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  constructor() {
    super();
    this.state = {
      isSolutionOpen: false
    };
  }

  setSolutionOpen = (state) => {
    // update the state to show the button
    this.setState({ isSolutionOpen: !this.state.isSolutionOpen })
  }

  render() {

    const { match } = this.props;
    let quiz_id = match.params.quiz_id;
    let quiz = quizzes.filter(function (q) {
      return q.id == quiz_id;
    })[0];

    let filtered_quizzes = quizzes.filter(function (q) {
      return quiz.related_quizes.includes(q.id);
    });

    document.title = "Quiz #" + quiz_id + " ・ Can you solve this UX/UI quiz? ・ YearOfUX";

    return (
      <div>
        {/* Google Tag Manager (noscript) */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GHRBRC" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        {/* End Google Tag Manager (noscript) */}

        <a href="https://www.yearofux.com">
        <header id="header">
          <div className="main-logo">
            <img src="/vanilla-files/assets/youx-white.png" id="youx-logo"></img>
          </div>
        </header>
        </a>

        <QuizHeader
          quizNumber={quiz.id}
          quizTitle={quiz.title}
        />

        <Fade>
        <QuizMetaStrip
          platform={quiz.platform}
          category={quiz.category}
          source={quiz.source}
          date={quiz.date}
        />
        </Fade>

        <Fade>
        <QuizPrompt
          promptText={quiz.prompt}
          promptHint={quiz.hint}
        />
        </Fade>

        <Fade>
        <QuizMedia
          isVideo={quiz.is_media_video}
          vimeoId={quiz.vimeo_id}
          mediaUrl={quiz.media}
        />
        </Fade>

        <Fade>
        <SolutionToggleBar
          onClick={this.setSolutionOpen}
          className={this.state.isSolutionOpen ? 'solution-toggle-open' : 'solution-toggle'}
          icon={this.state.isSolutionOpen ? "minus" : "plus"}
        />
        </Fade>

        {this.state.isSolutionOpen ?

          <SolutionBox solutions={quiz.solution} intro={quiz.solution_intro} />

          : null}


        {/* <Fade>
        <ShareQuiz
          quizId={quiz.id}
        />
        </Fade> */}

        <Fade>
        <DiscussQuiz />
        </Fade>

        <Fade>
        <div className="quiz-end">
          <h4>WOO HOO! 🥳</h4>
          <p>That's the end of this quiz. Have a great day!</p>
        </div>
        </Fade>


        <Footer />

      </div >


    );
  }
}

export default QuizPage;
