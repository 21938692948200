import React from 'react';
import Fade from 'react-reveal/Fade';

class QuizHeader extends React.Component {
    render() {
     return(
      <div className="hero-section-quiz">
         <Fade><h6>QUIZ {this.props.quizNumber}</h6></Fade>
         <Fade><h1 className="quiz-title-hero">{this.props.quizTitle}</h1></Fade>
      </div>
     );
    }
  }

  export default QuizHeader;