import React from 'react';
import Zoom from 'react-reveal/Zoom';

const institialState = {
    "subscriberEmail": "",
    "isSubmitted": 0,
    "errorRes": null,
    "isLoading": false,
};

class SubscribeInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = institialState;
        this.onChange = this.onChange.bind(this);
        this.submitSubscribe = this.submitSubscribe.bind(this);
    };

    onChange(e) {
        this.setState({ subscriberEmail: e.target.value });
    }

    onChangeFname(e) {
        this.setState({ subscriberFname: e.target.value });
    }

    onChangeLname(e) {
        this.setState({ subscriberLname: e.target.value });
    }

    onSuccessfulSubmission(response) {
        if (response.success === true) {
            this.setState({ isSubmitted: 1 });
            const apiToken = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOWYyODU3NjNjNzFlYmM3YzJmMjVhYzJkOTYwOTA3ZWI2YTNhZDU3ZWUzNGU2ZDg5NDM2M2E3NmU3OTFiOGVhYmY4NWE5YjBiMjNjNWUzNWQiLCJpYXQiOjE2MzMzNjc1MjcuNjI2NTk4LCJuYmYiOjE2MzMzNjc1MjcuNjI2NjA1LCJleHAiOjQ3ODY5NzExMjcuNjIyNzIsInN1YiI6IjEwMjE5MiIsInNjb3BlcyI6W119.Qnub8epp5JIixe55BHI8wYOZBcOj8YxRuvswyOL9-q1PP67DrlHqmVIz-wosq6sPVCbkpxlHr1R0GYlnd4TUig";
            let headers = {
                "Authorization": apiToken,
                "Content-Type": "application/json",
                "Accept": "application/json",
            };
            const url = new URL(
                "https://api.sender.net/v2/subscribers/groups/dBNLKY"
            );
            
            let data1 = {
                "subscribers": [this.state.subscriberEmail],
            };
            
            fetch(url, {
                method: "POST",
                headers, body: JSON.stringify(data1)
            }).then(response => response.json());    
        } else if (response.success === false) {
            this.setState({ isSubmitted: 2, errorRes: response.message });
        } else {
            this.setState({ isSubmitted: 3, errorRes: response.errors.email[0] });
        }
    }

    submitSubscribe() {

        const apiToken = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOWYyODU3NjNjNzFlYmM3YzJmMjVhYzJkOTYwOTA3ZWI2YTNhZDU3ZWUzNGU2ZDg5NDM2M2E3NmU3OTFiOGVhYmY4NWE5YjBiMjNjNWUzNWQiLCJpYXQiOjE2MzMzNjc1MjcuNjI2NTk4LCJuYmYiOjE2MzMzNjc1MjcuNjI2NjA1LCJleHAiOjQ3ODY5NzExMjcuNjIyNzIsInN1YiI6IjEwMjE5MiIsInNjb3BlcyI6W119.Qnub8epp5JIixe55BHI8wYOZBcOj8YxRuvswyOL9-q1PP67DrlHqmVIz-wosq6sPVCbkpxlHr1R0GYlnd4TUig";

        let headers = {
            "Authorization": apiToken,
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        let data = {
            "firstname": this.state.subscriberFname,
            "lastname": this.state.subscriberLname,
            "email": this.state.subscriberEmail,
        };

        this.setState({ isLoading: true });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        };
        fetch('https://api.sender.net/v2/subscribers', requestOptions)
            .then((response) => response.json())
            .then((responseJSON) => {
                this.setState({ isLoading: false });
                this.onSuccessfulSubmission(responseJSON);
            });
    }

    render() {
        return (
            <Zoom>
            <div>

                {this.state.isSubmitted === 0 ?

                    <div style={{ display: "inline-flex" }}>

                        {/* CATCH EM' ALL */}
                        <input type="text" name="" defaultValue="" placeholder="Enter your First Name" onChange={this.onChangeFname} className="first-name" />
                        <input type="text" name="" defaultValue="" placeholder="Enter your Last Name" onChange={this.onChangeLname} className="last-name" />

                        <input type="text" name="" defaultValue="" placeholder={this.props.placeholder} onChange={this.onChange} className="subscription-field" />
                        <button type="button" name="button" className="subscribe-button" onClick={this.submitSubscribe}>
                            {this.state.isLoading ?
                                <div class="loader"></div> : this.props.buttonText
                            }
                        </button>
                    </div> : null
                }

                {
                    this.state.isSubmitted === 1 ?

                        <p style={{ fontSize: '18px' }}>Thank You! Please check your 'Promotions', 'Junk' or 'Spam' folder for our welcome e-mail. We don't want you to miss it :)</p> : null
                }

                {
                    this.state.isSubmitted === 2 ?

                        <p style={{ fontSize: '18px' }}>Oops! It seems we already have you on our list. Please check your 'Promotions', 'Junk' or 'Spam' folder for our e-mails.</p> : null
                }

                {
                    this.state.isSubmitted === 3 ?

                        <div>
                            <div style={{ display: "inline-flex" }}>

                                <input type="text" name="" defaultValue="" placeholder={this.props.placeholder} onChange={this.onChange} className="subscription-field" />
                                <button type="button" name="button" className="subscribe-button" onClick={this.submitSubscribe}>
                                    {this.state.isLoading ?
                                        <div class="loader"></div> : this.props.buttonText
                                    }
                                </button>
                            </div>
                            <p style={{ fontSize: '18px' }}>Please enter a valid email address.</p>
                        </div> : null
                }
            </div >
            </Zoom>

        );
    }
}

export default SubscribeInput;