import React from 'react';

class DiscussQuiz extends React.Component {

    render() {
        return (
            <div className="discuss-container">
                <h5>DISCUSS</h5>
                <h6>Agree or Disagree with our solution? Let us know!</h6>

                <a href="mailto:admin@gleanux.com" target="_blank" className="email-link">
                    <div className="discuss-email">
                        <div>
                            <h6>SHARE YOUR FEEDBACK</h6>
                            <p>We'd love to hear it!</p>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/icons/email.svg`} alt="E-mail us your feedback" title="Send E-mail"/>
                    </div>
                </a>
            </div>
        );
    }
}

export default DiscussQuiz;