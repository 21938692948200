import React from 'react';

class QuizMedia extends React.Component {
    render() {
        return (
            <div className="quiz-media">
                {this.props.isVideo ?
                    <div className="media-video-container">
                        <iframe src={`https://player.vimeo.com/video/${this.props.vimeoId}`} className="media-video" frameBorder="0" allowFullScreen></iframe>
                    </div>
                    :

                    <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/quizzes/` + this.props.mediaUrl} alt="This is the quiz prompt image" />
                }
            </div>
        );
    }
}

export default QuizMedia;