import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';



class Footer extends React.Component {
    render() {
     return(
        <footer>
            <div className="main-logo">
                <Link to="/"><img src="/vanilla-files/assets/youx-black.png" id="youx-logo"></img></Link>
            </div>

            <div className="footer-tagline">Thoughtfully built for you &nbsp;😇</div>

            <div className="footer-links">
                <Link to="/about" style={{ color: this.props.aboutNav }}>About</Link>
                <a href="mailto:admin@gleanux.com" className="e-mail">Contact</a>
            </div>
         </footer>
     );
    }
  }

  export default Footer;