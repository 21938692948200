import React from 'react';
import Header from './components/common/header';
import Footer from './components/common/footer';
import SubscriptionBox from './components/common/subscription-box';
import '../src/css/why-sub.css';
import Fade from 'react-reveal/Fade';


class WhySub extends React.Component {

    componentDidMount(){
      document.title = "WHY SUBSCRIBE | YearOfUX ・ Fun bite-sized UX/UI quizzes";
    }

    render(){
      return (
  
      <div>
          {/* Google Tag Manager (noscript) */}
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GHRBRC" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          {/* End Google Tag Manager (noscript) */}

          <Header
            whySubNav="#7CF065"
            whySubSelect="none"
          />
  
  
        <Fade>
        <div className="hero-section hero-section-why">
          <div>
            <h1>Our Why. Our Purpose. Our Mission.<br />It's all for <span>you</span>!</h1>
          </div>
        </div>
        </Fade>
  

        <Fade bottom>
        <div className="content-block content-block-why">
          <h4>THE SPARK</h4>
          <p>What we observed on social media and various blogs, was this trend of comparing two UI screens that were placed side by side. While, it may seem like a quick way to test your skills, we paused to ask the question <strong>'What are these quizzes really testing?'</strong>.</p>
          <br /><br />

          <p>Think about it, spotting issues between two almost identical images is not testing your UI skills, rather your eyesight. To put it bluntly, any layman (we mean a non-designer) with good eyesight can spot the differences between those two options.</p>
          <br /><br />

          <p>On a UX front, none of these quizzes come with any background on what the problem is, or the task the user is trying to achieve with it. How could we make a decision on 'Which UI is better?' if we do not know the purpose behind the UI in the first place?</p>
          <br /><br />

          <p>Finally (and most importantly), we want to point out that, in the real-world when working on projects, you most likely will be given an experience and are expected to <strong>find the problems yourself</strong>. You may not get two or more options to choose from as the 'better ux'.</p>
          <br /><br />

          <p>Therefore, through the YearOfUX we want to challenge the norm and disrupt this trend to provide a more effective and meaningful solution. For each quiz, we give a prompt and a snapshot of the experience. That's it!</p>
          <br /><br />

          <p>It's up to you as the designer to read the prompt and decifer the problem. At the end of each quiz, we have given our solution and have stated the reasons behind it. We'd love to see your answers and hear your feedback, so feel free to reach out to us via e-mail.</p>
          <br /><br />

          <p>And with that, we hope you enjoy the YearOfUX.</p>
        </div>
        </Fade>

        <Fade>
        <div className="content-block content-block-why">
            <h4 style={{color: "#D7322E"}}>PLEASE STAY AWAY FROM THESE 👇</h4>
            <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/why-youx.png`} alt="Why you should choose YearOfUX" />
        </div>
        </Fade>

  
  
  
        <SubscriptionBox />
  
  
  
  
        <Footer />
  
      </div>
  
        
      );
    }
  }
  
  export default WhySub; 