import React from 'react';

class PrimaryButton extends React.Component {
  render() {
    return (
      <button type="button" name="button" onClick={this.props.clickEvent}>{this.props.text}</button>
    );
  }
}

export default PrimaryButton;