import React from 'react';
import Header from './components/common/header';
import Footer from './components/common/footer';
import SubscriptionBox from './components/common/subscription-box';
import '../src/css/about.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import PrimaryButton from './components/common/primary-button';


class About extends React.Component {

    componentDidMount(){
      document.title = "ABOUT | YearOfUX ・ Fun bite-sized UX/UI quizzes";
    }

    render(){
      return (
  
      <div>
          {/* Google Tag Manager (noscript) */}
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GHRBRC" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          {/* End Google Tag Manager (noscript) */}

          <Header
            aboutNav="#7CF065"
            aboutSelect="none"
          />
  
  
        <Fade>
        <div className="hero-section hero-section-about">
          <h1>What made the YearOfUX?</h1>
        </div>
        </Fade>


        <Fade bottom>
        <div className="content-block content-block-about">
          <h4>OUR STORY</h4>
          <p>Like most design projects, our's too started off with a problem.<br /><br />The problem simply there being a lack of quality resources out there for UX/UI designers to practice their skills. Sure, there are a few solutions out there, but they aren't as effective in our (humble) opinion. We've explained our reasons for the same <Link to="/whysub">here</Link>.<br /><br />Long story short, YearOfUX emerged with the aim of providing quality bite-sized quizzes for <strong>UX/UI designers to hone their skills and learn</strong> from real-world problems.<br /><br />Our quizzes are designed in a way to prompt users to <strong>think and reason</strong> with their ideas in order to solve a problem.<br /><br />Skeptical? Feel free to <Link to={`/quiz-sample/0`}>give it a try yourself</Link>! We are open to feedback (as most designers should be) and are looking forward to hearing from you.<br /><br /><strong>Happy Quizzing!</strong></p>
        </div>
        </Fade>
        <a id="contact"></a>


        <Fade>
        <div className="hero-section hero-section-about">
          <h1>Okay, so what's GleanUX then?</h1>
        </div>
        </Fade>


        <div className="content-block content-block-about content-block-two-col">
          <Fade>
          <div>
            <h4>THE BRIEF</h4>
            <p>GleanUX is a brand with an aim to help designers develop a rich UX mindset.<br /><br />They offer products and services tailored to get you started in your UX career, or unstuck, if you've been in the game for a while.<br /><br /> GleanUX was founded by <a href="https://www.sushrutmangeshikar.com" target="_blank">Sushrut Mangeshikar</a>, a Digital Product Designer with over 5 years of industry experience.</p>
            <div className="two-button-container">
              <a href="https://www.gleanux.com" target="_blank"><div className="primary-button"><PrimaryButton text="VISIT WEBSITE"/></div></a>
              <a href="mailto:admin@gleanux.com" className="e-mail"><div className="secondary-button"><PrimaryButton text="CONTACT US"/></div></a>
            </div>
          </div>
          </Fade>

          <Fade right>
          <div className="profile-image">
            <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/profile-image.png`}/>
          </div>
          </Fade>
        </div>

        

{/* 
        <div className="content-block content-block-about">
          <h4>CONTACT US</h4>
          <div className="contact-block-container">
            <div className="contact-block">
              <h5>FOR DESIGNERS</h5>
              <p>Have feedback? Quiz suggestions? Or just want to chat? Feel free to reach out to us with your query and we shall get back to you.</p>
              <a href="mailto:admin@gleanux.com">admin@gleanux.com</a>
            </div>


            <div className="contact-block">
              <h5>FOR BRANDS</h5>
              <p>We conduct Expert UX Audits of websites and apps. Do reach out with your request and we shall get back to you.</p>
              <a href="mailto:admin@gleanux.com">admin@gleanux.com</a>
            </div>
          </div>
        </div>
   */}
  
  
        <SubscriptionBox />
  
  
  
  
        <Footer />
  
      </div>
  
        
      );
    }
  }
  
  export default About; 