import React from 'react';




class SolutionToggleBar extends React.Component {
  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <h4>VIEW OUR SOLUTION</h4>
        <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/icons/${this.props.icon}.svg`} alt="click to expand/collapse" id="toggle-icon" />
      </div>
    );
  }
}

export default SolutionToggleBar;