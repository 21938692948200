import React from 'react';
import PrimaryButton from './primary-button';
import { Link } from 'react-router-dom';

let scroll = 0;

function logit() {
  scroll = window.pageYOffset;
  handleScroll(scroll);
}

function handleScroll(scroll) {
  var head = document.getElementById("header");
  if (scroll > 30 || scroll > 30) {
    head.style.boxShadow = "0px 4px 5px 1px rgba(3, 17, 1, 0.31)";
    head.style.webkitBoxShadow = "0px 4px 5px 1px rgba(3, 17, 1, 0.31)";
    head.style.mozBoxShadow = "0px 4px 5px 1px rgba(3, 17, 1, 0.31)";
  } else {
    head.style.boxShadow = "none";
    head.style.webkitBoxShadow = "none";
    head.style.mozBoxShadow = "none";
  }
}

class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      isMobileMenuOpen: false
    };
  }

  setMobileMenuOpen = (state) => {
    // update the state to show the button
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen })
  }

  componentDidMount() {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  };

  render() {
    return (
      <header id="header">
        <div className="main-logo">
          <Link to="/"><img src="/vanilla-files/assets/youx-white.png" id="youx-logo"></img></Link>
        </div>

        <nav>
          <ul>
            <li><Link to="/about" style={{ color: this.props.aboutNav, textDecoration: this.props.aboutSelect }}>What Is This?</Link></li>
            <li><Link to="/whysub" style={{ color: this.props.whySubNav, textDecoration: this.props. whySubSelect }}>Why Subscribe?</Link></li>
            {/* <a href="https://www.sushrutmangeshikar.com" target="_blank" rel="noreferrer"><li>BLOG</li></a> */}
          </ul>
          <Link to="/" style={{ textDecoration: "none" }}><PrimaryButton text="SUBSCRIBE - It's Free" /></Link>

          {this.state.isMobileMenuOpen ? <img src={process.env.PUBLIC_URL + "/vanilla-files/assets/icons/x.svg"} alt="Close Menu" id="mobile-nav-toggle" onClick={this.setMobileMenuOpen} /> :
            <img src={process.env.PUBLIC_URL + "/vanilla-files/assets/icons/menu.svg"} alt="Open Menu" id="mobile-nav-toggle" onClick={this.setMobileMenuOpen} />}
        </nav>

        {this.state.isMobileMenuOpen ?

          <div id="mobile-nav">
            <ul>
              <li><Link to="/about" style={{ color: this.props.aboutNav, textDecoration: this.props.aboutSelect }}>What Is This?</Link></li>
              <li><Link to="/whysub" style={{ color: this.props.whySubNav, textDecoration: this.props. whySubSelect }}>Why Subscribe?</Link></li>
            </ul>
            <Link to="/" style={{ textDecoration: "none" }}><button type="button" name="button">SUBSCRIBE - It's Free</button></Link>
          </div>

          : null}
      </header>
    );
  }
}

export default Header;