import React from 'react';

class SectionHeading extends React.Component {
    render() {
     return(
        <h2>{this.props.title}</h2>
     );
    }
  }

  export default SectionHeading;