import React from 'react';
import Home from './home';
import About from './about';
import QuizPage from './quiz-page';
import WhySub from './why-sub';
import QuizSample from './quiz-sample';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

class App extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/quiz-page/:quiz_id" component={QuizPage} />
            <Route exact path="/quiz-sample/:quiz_id" component={QuizSample} />
            <Route exact path="/whysub" component={WhySub} />
            <Route exact path="/about" component={About} />
            <Route default path="/" />
          </Switch>
        </ScrollToTop>
      </Router>

    );
  }
}

export default App;
