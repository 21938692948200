import React from 'react';
import Fade from 'react-reveal/Fade';



class SolutionBox extends React.Component {
    render() {
        return (
            <div className="solution-box">
                <div>
                    <Fade><p className="solution-intro">{this.props.intro}</p></Fade>
                    <ol>
                        {
                            this.props.solutions.map(solution => (
                                <div>
                                    {/* POINT */}
                                    <Fade><li>{solution.title}</li></Fade>
                                    {solution.media ?
                                        <Fade><img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/quizzes/` + solution.media} alt="" /></Fade>
                                        : null}
                                    <Fade><p dangerouslySetInnerHTML={{ __html: solution.content }} /></Fade>
                                </div>
                            ))
                        }
                    </ol>
                </div>
            </div>

        );
    }
}

export default SolutionBox;