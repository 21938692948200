import React from 'react';

class QuizMetaStrip extends React.Component {
  render() {
    return (
      <div className="quiz-meta-strip">
        <div className="quiz-meta-container">
          {/* PLATFORM */}
          <div className="quiz-meta-block">
            <h5>PLATFORM</h5>
            <p>{this.props.platform}</p>
          </div>

          {/* CATEGORY */}
          <div className="quiz-meta-block">
            <h5>CATEGORY</h5>
            <p>{this.props.category}</p>
          </div>

          {/* SOURCE */}
          <div className="quiz-meta-block">
            <h5>SOURCE</h5>
            <p>{this.props.source}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizMetaStrip;