import React from 'react';
import SubscribeInput from './subscribe-input';
import Fade from 'react-reveal/Fade';

class SubscriptionBox extends React.Component {
    render() {
     return(
        <Fade>
        <div id="subscription-box">
            <p>Develop your UX muscle, one week at a time! 💪<br />You <span style={{color: '#7CF065'}}>most likely</span> won't regret it.</p>
            <SubscribeInput placeholder="Enter your e-mail here" buttonText="SUBSCRIBE" />
         </div>
        </Fade>
     );
    }
  }

  export default SubscriptionBox;