import React from 'react';
import PrimaryButton from './components/common/primary-button';
import Header from './components/common/header';
import SubscribeInput from './components/common/subscribe-input';
import SubscriptionBox from './components/common/subscription-box';
import SectionHeading from './components/common/section-heading';
import Footer from './components/common/footer';
import '../src/css/quizzes.css';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { HashLink } from 'react-router-hash-link';



// let quiz_limit = 6;

class Home extends React.Component {

  componentDidMount(){
    document.title = "YearOfUX ・ Fun bite-sized UX/UI quizzes";
  }

  // constructor() {
  //   super()
  //   quizzes.sort(function (a, b) { return b.id - a.id });
  //   this.state = {
  //     quizzes: quizzes.slice(0, quiz_limit)
  //   };
  // }

  // loadMoreQuizzes = () => {
  //   quiz_limit += 6;
  //   console.log(quiz_limit);
  //   this.setState({ quizzes: quizzes.slice(0, quiz_limit) })
  // }

  render() {
    return (

      <div>
        {/* Google Tag Manager (noscript) */}
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GHRBRC" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        {/* End Google Tag Manager (noscript) */}

        <Header quizzesNav="#7CF065" />


        <div className="hero-section hero-section-home" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/vanilla-files/assets/background.png"})` }}>
          <div>
            <Fade><h1>Sharpen your UX/UI skills through<br />real-world problems.</h1></Fade>
            <Fade><p className="hero-sub-title">(And yeah, it's totally FREE!)</p></Fade>
            <Zoom><SubscribeInput placeholder="Enter your e-mail here" buttonText="SUBSCRIBE" /></Zoom>
            <Fade><div className="try-link">Not sure about it? <HashLink to="#try-sample">TRY A SAMPLE 👇</HashLink></div></Fade>
          </div>
        </div>




        <div className="content-block">
          <Fade bottom><SectionHeading title="What Is This?" /></Fade>
          <Fade bottom><p>YearOfUX is a weekly newsletter, which brings you challenging UX/UI quizzes based on real-world problems. Our quizzes vary in difficulty, but are designed to enrich your UX mindset over the course of 52 weeks (ie. an entire year.)</p></Fade>
          <Fade bottom><Link to={`/about`}><div className="primary-button"><PrimaryButton text="READ OUR STORY"/></div></Link></Fade>
        </div>



        <div className="content-block">
          <Fade bottom><SectionHeading title="Why Subscribe?" /></Fade>
          <Fade bottom><p>If you're new to UX, or want to hone your UX/UI skills, practicing on a regular basis is immensely beneficial. Each quiz is carefully crafted keeping in mind usability heuristics and the laws of UX.</p></Fade>
          <div className="why-sub-asset">
            <video loop autoPlay muted webkit-playsinline="true" playsInline>
              <source src={`${process.env.PUBLIC_URL}/vanilla-files/assets/why-sub.mp4`} type="video/mp4"/>
            </video>
          </div>
          <Fade  bottom>
            <div className="two-button-container">
              <Link to={`/whysub`}><div className="primary-button"><PrimaryButton text="LEARN MORE"/></div></Link>
              <HashLink to="#try-sample"><div className="secondary-button"><PrimaryButton text="TRY A SAMPLE"/></div></HashLink>
            </div>
          </Fade>
          <a id="try-sample"></a>
        </div>




        <div className="content-block">
          <Fade bottom><SectionHeading title="Still Not Convinced? Try It Now!" /></Fade>
          <Fade bottom><p>We get it, newsletter sign-ups are scary and we surely don't want to intrude your inbox. Therefore, we've prepared a sample quiz to give you a taste of what we have to offer. Go for it!</p></Fade>
          <div className="quiz-block">
            <img src={`${process.env.PUBLIC_URL}/vanilla-files/assets/quizzes/0/0-quizcover.png`} alt="Hidesign Cover Image"/>
            <div className="quiz-meta">
              <div className="quiz-source">HIDESIGN</div>
              <p>Can you think of 2 fixes for Hidesign's filter experience?</p>
              <Link to={`/quiz-sample/0`}><span className="start-quiz">START QUIZ</span></Link>
            </div>
          </div>
        </div>








{/* TO BE DELETED://///
        <div className="quiz-container">

          {this.state.quizzes.map(quiz => (
            <Fade>
            <QuizBlock
              quizCover={quiz.cover_image}
              quizCoverOff="Image of quiz"
              quizNumber={"#" + quiz.id}
              quizDate={quiz.date}
              quizTitle={quiz.title}
              quizId={quiz.id}
              key={quiz.id}
              quizData={quiz}
            />
            </Fade>

          ))}

        </div>

        <div className="more-quizzes-button">
          {this.state.quizzes.length != quizzes.length ?
            <PrimaryButton text="MORE QUIZZES" clickEvent={this.loadMoreQuizzes} />
            : null}
        </div> */}

        <SubscriptionBox />




        <Footer />

      </div>


    );
  }
}

export default Home;
