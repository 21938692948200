import React from 'react';
import PropTypes from "prop-types";
import '../src/css/quiz-page.css';
import Header from './components/common/header';
import SubscriptionBox from './components/common/subscription-box';
import Footer from './components/common/footer';
import SubscribeInput from './components/common/subscribe-input';
import QuizMetaStrip from './components/quiz-page/quiz-meta-strip';
import QuizPrompt from './components/quiz-page/quiz-prompt';
import QuizMedia from './components/quiz-page/quiz-media';
import SolutionToggleBar from './components/quiz-page/solution-toggle';
import SolutionBox from './components/quiz-page/solution-box';
import Fade from 'react-reveal/Fade';
import quizzes from './database/index.json';

class QuizSample extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  constructor() {
    super();
    this.state = {
      isSolutionOpen: false
    };
  }

  setSolutionOpen = (state) => {
    // update the state to show the button
    this.setState({ isSolutionOpen: !this.state.isSolutionOpen })
  }

  render() {

    const { match } = this.props;
    let quiz_id = match.params.quiz_id;
    let quiz = quizzes.filter(function (q) {
      return q.id == quiz_id;
    })[0];

    let filtered_quizzes = quizzes.filter(function (q) {
      return quiz.related_quizes.includes(q.id);
    });

    document.title = "Can you solve this UX/UI quiz? ・ YearOfUX";

    return (
      <div>
        {/* Google Tag Manager (noscript) */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GHRBRC" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        {/* End Google Tag Manager (noscript) */}

        
        <Header />

        <div className="hero-section-quiz">
         <Fade><h6>SAMPLE QUIZ</h6></Fade>
         <Fade><h1 className="quiz-title-hero">Can you think of 2 fixes for Hidesign's filter experience?</h1></Fade>
        </div>

        <Fade>
        <QuizMetaStrip
          platform={quiz.platform}
          category={quiz.category}
          source={quiz.source}
          date={quiz.date}
        />
        </Fade>

        <Fade>
        <QuizPrompt
          promptText={quiz.prompt}
          promptHint={quiz.hint}
        />
        </Fade>

        <Fade>
        <QuizMedia
          isVideo={quiz.is_media_video}
          vimeoId={quiz.vimeo_id}
          mediaUrl={quiz.media}
        />
        </Fade>

        <Fade>
        <SolutionToggleBar
          onClick={this.setSolutionOpen}
          className={this.state.isSolutionOpen ? 'solution-toggle-open' : 'solution-toggle'}
          icon={this.state.isSolutionOpen ? "minus" : "plus"}
        />
        </Fade>

        {this.state.isSolutionOpen ?

          <SolutionBox solutions={quiz.solution} intro={quiz.solution_intro} />

          : null}




          <Fade>
          <div className="like-it-container">
            <h5>DID YOU LIKE IT?</h5>
            <h6>Well, if it's a yes, what are you waiting for? Join us 😊</h6>
            <SubscribeInput placeholder="Enter your e-mail here" buttonText="SUBSCRIBE" />
          </div>
          </Fade>

        <SubscriptionBox />







        <Footer />

      </div >


    );
  }
}

export default QuizSample;
